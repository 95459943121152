<template>
  <v-container id="buying" tag="section" class="container">
    <section id="buy" class="background">
      <v-container class="text-center">
        <div id="wrapper1">
          <div class="text1">
            <h1 class="header">
              Unlock the Value of Your Home: Let's Make Your Sale a Success
            </h1>
            </br>
            <div class="sell">
              <p>
                Considering selling your home and maximizing your equity? I'm
                here to simplify your sale, turning this stressful time into a
                rewarding journey. With expert negotiation, attention to detail,
                and innovative marketing, I aim to enhance your experience by
                highlighting your home’s unique features to attract ideal buyers
                and secure the best price.
              </p>
              <p>
                Let’s collaborate to fully realize your property's potential and
                ensure a successful sale.
              </p>
            </div>
          </div>
          <div class="image1">
            <v-img
              :src="require(`@/assets/images/mahsa_selling.JPG`)"
              height="100%"
            />
          </div>
        </div>
        <v-container />
      </v-container>
    </section>

    <section id="buy" class="background">
      <v-container class="services">
        <h1 class="servicesTitle">My services at a glance</h1>
        <v-row no-gutters>
          <v-col v-for="n in 6" :key="n" cols="12" sm="4">
            <v-card class="pa-2 cards" flat color="transparent" tile>
              <v-img
                :src="require(`@/assets/icons/${services[n - 1].icon}`)"
                max-height="100"
                max-width="100"
                class="icon"
              />
              <h2>{{ services[n - 1].title }}</h2>
              <p>{{ services[n - 1].description }}</p>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
export default {
  name: "SellingComponent",

  components: {
    Social: () => import("@/components/home/Social"),
    Subscribe: () => import("@/components/home/Subscribe"),
  },

  data: () => ({
    test: [11, 44, 55],
    services: [
      {
        title: "Consultation",
        icon: "consultation.JPG",
        description:
          "This is a chance for us to dive deep into the story of your home and create a tailored roadmap for you. We'll align on the perfect strategy, nail down timelines, brainstorm marketing magic, and discuss how I can streamline the whole process for you.",
      },
      {
        title: "Staging",
        icon: "staging.JPG",
        description:
          "Maximize your sale with our staging and decluttering services highlighting your home’s best features, reducing market time and boosting the sale price for a faster and more profitable transaction.",
      },
      {
        title: "Photography and Videography",
        icon: "photography.JPG",
        description:
          "In our visual market, listing presentation is key. I highlight your home’s beauty and uniqueness with professional photos and videos, capturing buyer interest and paving the way for a successful sale.",
      },
      {
        title: "Marketing",
        icon: "marketing.JPG",
        description:
          "Efficient marketing is crucial for a quick, top-dollar sale. I create a custom strategy targeting your home's audience, utilizing social media and Google ads to highlight your property.",
      },
      {
        title: "Prospecting and Networking ",
        icon: "networking.JPG",
        description:
          "Networking is vital in real estate and I leverage my network of realtors, buyers, and investors for your listing's broad exposure.",
      },
      {
        title: "Seamless and Fun Experience",
        icon: "fun.JPG",
        description:
          "Selling your home is a significant milestone, and I'm here to ensure it's not just stress-free but also enjoyable. I will simplify the process, guiding you every step of the way with ease and a touch of humour.",
      },
    ],
  }),
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.container {
  max-width: 100%;
}

.header {
  font-size: 2.5em;
}

.sell {
  line-height: 2.5;
}

#wrapper1 {
  height: 90%;
  width: 1200px;
  align-content: center;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.image1 {
  width: 50%;
}

.text1 {
  height: 50%;
  width: 50%;
  line-height: 1.4;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0em;
  font-weight: 350;
  font-style: normal;
  padding-right: 25px;
}

.services {
  width: 70%;
  background-color: #F9F9F9;
}

.servicesTitle {
  font-size: 45px;
}

.icon {
  margin-left: -20px;
}

.background {
  background-color: #ffffff;
}

@media only screen and (max-width: 600px) {
  #wrapper1 {
    width: 100%;
  }

  .header {
    font-size: 1.75em;
  }

  .image1 {
    height: 30em;
    width: 100%;
    padding-bottom: 15px;
  }

  .text1 {
    height: 20%;
    width: 100%;
  }

  .services {
    width: 100%;
  }

  .servicesTitle {
    font-size: 30px;
  }
}
</style>
